.container {
  height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;

  > div {
    height: inherit !important;
  }

  &.fullHeight {
    height: 100vh;

    > div {
      height: initial !important;
    }
  }

  .loginBtn {
    cursor: pointer;
    padding: 16px 24px;
    border: 1px solid white;
    border-radius: 8px;

    &:hover {
      background-color: #2A292E;
    }
  }
}